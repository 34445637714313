import React from 'react';
import { FaArrowAltCircleRight } from "react-icons/fa";

const Product = () => {
  return (
    <div>
      {/* Hero section with background image */}
      <div className="min-h-80 flex bg-cover bg-center" style={{ backgroundImage: "url('./Images/Product-baner.avif')" }}>
        <div className="w-full">
          <div className="text-black p-4 md:p-8 rounded-lg">
            <h1 className="text-3xl font-bold text-white mt-24">PRODUCT DEVELOPMENT</h1>
          </div>
        </div>
      </div>


      {/* Main content */}
      <div className="container mx-auto mt-10">
        {/* Introduction */}
        <p className="text-center mt-4">
          VILA Campus is a product development company. We have a team of more than twenty years of experience in software development. We develop software in such a manner that it's easily customizable as per requirements. With newly developed software products, we can bring change in the current market and give users a better customer experience. Specific designs and workflows for the application as well as the tools and technology on which the solution will be built are identified. Trainings will be provided to the customer team after deployment of the product. Customization and maintenance activities will be taken care of within committed timelines.
        </p>

        {/* Product details grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
          {/* Product details */}
          <div className="p-4 ">
            <img src="./Images/Product1.jpg" alt="Product-1img" className="rounded-lg shadow-md" />
          </div>

          {/* Product image */}
          <div className="p-4">
            <h2 className="text-3xl font-bold mb-4">Education ERP Software</h2>
            <p className="mb-5">Software product Designed to simplify the day-to-day tasks of Educational Institutions. One solution for all your requirements.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Full featured Student Management System.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Cloud based online software for Colleges, Schools and Training centres.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Software will be integrated within the client's website, showing only integrated school/college name.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Customization of features can be done as per requirements.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Regular technical support and trainings will be provided.</p>
            <p className='mt-6 font-bold text-xl text-sky-700' ><a href="https://sterp.vlinkjobs.in" target="_blank" rel="noreferrer">Demo Link</a>  </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
          {/* Product details */}
          <div className="p-4 ">
            <h2 className="text-3xl font-bold mb-4">GPS Tracking</h2>
            <p className="mb-5">GPS tracking is the real-time monitoring of the location of an object or person using Global Positioning System technology.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" /> Choose appropriate GPS tracking devices or modules based on your requirements. Consider factors such as accuracy, battery life, ruggedness, and connectivity options (GSM, satellite, etc.).</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Develop the software for data collection, storage, and visualization. This may involve creating a web-based dashboard, mobile apps, or integrating with existing software systems.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" /> Implement a robust data management system to handle the incoming GPS data efficiently. This includes storage, retrieval, and processing of location data.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Set up alerts for various events such as speeding, unauthorized use, or device tampering. Notifications can be sent via SMS, email, or push notifications.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" /> Implement security measures to protect the integrity and confidentiality of the GPS data. This includes encryption, authentication, and access control mechanisms.</p>
          </div>

          {/* Product image */}
          <div className="p-4">
            <img src="./Images/Product-Gps.avif" alt="Product-1img" className="rounded-lg shadow-md" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10">
          {/* Product details */}
          <div className="p-4 ">
            <img src="./Images/Product-Ecommers.avif" alt="Product-1img" className="rounded-lg shadow-md" />
          </div>

          {/* Product image */}
          <div className="p-4">
            <h2 className="text-3xl font-bold mb-4">E-commerce</h2>
            <p className="mb-5">Build an e-commerce project with a user-friendly platform, efficient product catalog management, seamless UX design, secure payment gateway integration, and comprehensive marketing and analytics tools.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Choose the right e-commerce platform that suits your business needs, whether it's a hosted solution like Shopify or self-hosted options like WooCommerce or Magento, considering factors such as customization, scalability, and ease of use.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" /> Implement a robust system for managing your product catalog, including features for adding, editing, categorizing, and displaying products, along with inventory management and SKU tracking.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Focus on creating a seamless and intuitive user experience across all devices, ensuring easy navigation, quick loading times, clear product information, and a streamlined checkout process to maximize conversion rates and customer satisfaction.</p>
            <p className='mt-6 font-bold text-xl text-sky-700' ><a href="https://vshopping.jobzlinker.com/" target="_blank" rel="noreferrer">Ecommerce Demo Link</a></p>
            <p className='mt-2 font-bold text-xl text-sky-700' ><a href="https://smartcook.jobzlinker.com/" target="_blank" rel="noreferrer">Food Delivery Demo Link</a></p>
            <p className='mt-2 font-bold text-xl text-sky-700' ><a href="https://printferry.com/" target="_blank" rel="noreferrer">Online Printshop</a></p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-10 mb-10">
          {/* Product details */}
          <div className="p-4 ">
            <h2 className="text-3xl font-bold mb-4">Training And Empolyment</h2>
            <p className="mb-5">Develop an employment platform offering job listings, resume management, candidate matching, employer tools, and analytics for efficient recruitment and career management.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Provide a comprehensive database of job listings across various industries and locations, allowing employers to post vacancies and candidates to search and apply for positions.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Offer tools for candidates to create and manage their resumes/profiles, including features for uploading documents, highlighting skills and experiences, and tracking application statuses.</p>
            <p className="mb-2"><FaArrowAltCircleRight className="inline mr-2" />Implement algorithms or filters to match candidates with suitable job opportunities based on their skills, experience, qualifications, and preferences, enhancing the efficiency of the job search process.</p>
            <p className='mt-6 font-bold text-xl text-sky-700' ><a href="https://vlinkjobs.com/" target="_blank" rel="noreferrer">Online Jobs Portal</a></p>
          </div>

          {/* Product image */}
          <div className="p-4">
            <img src="./Images/Product-Emply.avif" alt="Product-1img" className="rounded-lg shadow-md" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
