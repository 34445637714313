/* import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx';
import axios from 'axios';
import { smsApiUrl, smsBodyText, smsChannel, smsPEID, smsPassword, smsRoute, smsSenderId, smsTemplateId, smsUserName } from '../../appconfig';
const https = require('https'); 


const SendSMS = ({ isAuthenticated, setIsAuthenticated }) => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/")
        }
    }, [isAuthenticated, navigate])
    return (
        <div className='min-h-[500px] md:max-w-7xl mx-auto px-4 py-2'>
            <div className='flex justify-between gap-4'>
                <h3 className='font-bold text-blue-800 text-lg' >Send SMS to contacts</h3>
                <button onClick={() => { setIsAuthenticated(false) }} className='p-2 bg-blue-500 border-2 border-blue-500 text-sm font-semibold text-white rounded-lg'>Sign Out</button>
            </div>
            <div className='flex md:flex-row flex-col md:gap-10 gap-2 my-4'>
                <Card header={"VLinks Jobs"} />
                <Card header={"Silver Screen book"} />
            </div>
        </div>
    )
}


let smsParams = {
    "DCS": 0,
    "flashsms": 0,
    "user":smsUserName,
    "password":smsPassword,
    "channel":smsChannel,
    "route":smsRoute,
    "peid":smsPEID,
    "senderid":smsSenderId,
    "dlttemplateid":smsTemplateId,
}


const Card = ({ header }) => {
  const [contacts, setContacts] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setContacts(file);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(header==="VLinks Jobs"){
        smsParams.text = smsBodyText.replace("{#var#}", "https://vlinkjobs.com/");
    }else{
        smsParams.text = smsBodyText.replace("{#var#}", "https://silverscreenbook.com/");
    }
    
    if (contacts) {
      try {
        const data = await contacts.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Send SMS for each contact
        await Promise.all(jsonData.map(async (number) => {
            smsParams.number = number.mobile;
            console.log(smsParams);
          await axios.get(smsApiUrl, {
            params: smsParams,
            httpsAgent: new https.Agent({  
                rejectUnauthorized: false
              })
          })
        })); 

        alert('SMS sent successfully');
      } catch (error) {
        console.error('Error reading file:', error);
        alert('Failed to send SMS');
      }
    }
  };

  return (
    <div className="w-[350px] shadow-lg h-[400px] border-2 border-gray-200 py-3 px-2">
      <div className="flex flex-col gap-2">
        <h1 className="font-semibold text-blue-700">{header}</h1>
        <p className="text-sm">
          Send SMS to register on the {header} website for your contacts. Select an Excel file containing the contacts to send SMS messages.
        </p>
      </div>
      <form className='my-6' onSubmit={handleSubmit}>
        <div className="w-full">
          <label htmlFor="contacts" className="block text-sm font-medium leading-6 text-gray-900">
            Select Excel file:
          </label>
          <div className="mt-2 w-full">
            <input
              type="file"
              accept=".xlsx"
              name="contacts"
              id="contacts"
              onChange={handleFileChange}
              className="block rounded-lg w-full flex-1 border-[1px] border-gray-400 focus-visible:border-gray-400 font-semibold bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              required
            />
          </div>
        </div>
        <div className="my-4 flex justify-end">
                <button
                  type="submit"
                  className="text-sm font-semibold bg-blue-600 p-2 text-white rounded-lg w-28"
                  disabled={!contacts}
                >
                  Send SMS
                </button>
              </div>
      </form>
    </div>
  );
};



export default SendSMS */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { baseUrl } from '../../appconfig';

const SendSMS = () => {
    const navigate = useNavigate();
    const token =localStorage.getItem("vcptoken")
    
    useEffect(() => {
        if (!token) {
            navigate("/");
        }
    }, [token, navigate]);

    return (
        <div className='min-h-[500px] md:max-w-7xl mx-auto px-4 py-2'>
            <div className='flex justify-between gap-4'>
                <h3 className='font-bold text-blue-800 text-lg'>Send SMS to contacts</h3>
                <button onClick={() => {localStorage.removeItem("vcptoken"); navigate("/");}} className='p-2 bg-blue-500 border-2 border-blue-500 text-sm font-semibold text-white rounded-lg'>Sign Out</button>
            </div>
            <div className='flex md:flex-row flex-col md:gap-10 gap-2 my-4'>
                <Card header={"VLinks Jobs"} token={token} />
                <Card header={"Silver Screen book"} token={token} />
            </div>
        </div>
    );
};

const Card = ({ header,token }) => {
    const [contacts, setContacts] = useState(null);
    const [res, setRes] = useState({});
    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setContacts(file);
        setRes({});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (contacts) {
            try {
                const data = await contacts.arrayBuffer();
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet);
                const formatedData=jsonData.map(data=>({mobile:String(data.mobile),fullName:data.fullName}));
                
                const serverdata = {domain:header==="VLinks Jobs" ? "https://vlinkjobs.com" : "https://silverscreenbook.com",
                    contacts:formatedData }
                const res = await axios.post(`${baseUrl}notification/sendSMSNotification`,serverdata, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                })
                setRes(res.data?.result)
                alert('SMS Sent Successfully');
                setContacts(null);
               setFileInputKey(Date.now()); 
            } catch (error) {
                console.error('Error reading file or sending SMS:', error);
                alert('Failed to send SMS');
            }
        }
    };

    return (
        <div className="w-[350px] shadow-lg h-[350px] border-2 border-gray-200 py-3 px-2">
            <div className="flex flex-col gap-2">
               {header==="VLinks Jobs"? <h1 className="font-bold text-blue-700">{header}</h1>:<h1 className="font-bold text-red-800">{header}</h1>}
                <p className="text-sm">
                    Send SMS to register on the {header} website for your contacts. Select an Excel file containing the contacts to send SMS messages.
                </p>
            </div>
            <form className='my-6' onSubmit={handleSubmit}>
                <div className="w-full">
                    <label htmlFor="contacts" className="block text-sm font-medium leading-6 text-gray-900">
                        Select Excel file:
                    </label>
                    <div className="mt-2 w-full">
                        <input
                         key={fileInputKey}
                            type="file"
                            accept=".xlsx"
                            name="contacts"
                            id="contacts"
                            onChange={handleFileChange}
                            className="block rounded-lg w-full flex-1 border-[1px] border-gray-400 focus-visible:border-gray-400 font-semibold bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            required
                        />
                    </div>
                </div>
                <div className="my-4 flex justify-end">
                    <button
                        type="submit"
                        className={`text-sm font-semibold ${!contacts? (header==="VLinks Jobs" ? "bg-blue-200":"bg-red-200"):(header==="VLinks Jobs" ? "bg-blue-700":"bg-red-700")} p-2 text-white rounded-lg w-28`}
                        disabled={!contacts}
                    >
                        Send SMS
                    </button>
                </div>
                <div className="my-4 flex justify-start">
                   { res && res.msgSentCount !== undefined  && <p className='font-bold text-sm'>SMS Sent {" "} {res.msgSentCount}/{res.totalContacts}</p>}
                </div>
            </form>
        </div>
    );
};

export default SendSMS;
