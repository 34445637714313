import React from 'react';
import { FaCircleCheck } from "react-icons/fa6";

const About = () => {
  return (
    <div>
      {/* Hero section with background image */}
      <div className="min-h-80 flex bg-cover bg-center" style={{ backgroundImage: "url('./Images/About-baner.avif')" }}>
        <div className="flex items-center justify-center w-full">
          <div className="text-white p-4 md:p-8 rounded-lg max-w-md text-center">
            <h1 className="text-4xl md:text-6xl lg:text-3xl font-bold mb-2 md:mb-4">ABOUT VILA CAMPUS</h1>
          </div>
        </div>
      </div>



      <div className='container mx-auto mt-10 px-4'>
        <div className="max-w-5xl mx-auto p-6">
          <h2 className="text-2xl font-bold mb-4">Introducing Our Cutting-Edge Software Development Services!</h2>
          <p className="text-gray-600 mb-4">With our dedicated team of experts, you can take your business to new heights by leveraging the power of custom software solutions. </p>

          <div className="benefits">
            <h3 className="text-xl font-semibold mb-2">Our Software Development services offer a wide range of benefits, including:</h3>

            <ul className="list-disc list-inside">
              <li className="mb-2 flex items-start">
                <strong className="text-green-500 mr-2">&#10003;</strong>
                <span>Increased Efficiency: Streamline your business processes with tailor-made software, saving time and resources.</span>
              </li>
              <li className="mb-2 flex items-start">
                <strong className="text-green-500 mr-2">&#10003;</strong>
                <span>Enhanced Productivity: Optimize workflow and improve collaboration for outstanding results.</span>
              </li>
              <li className="mb-2 flex items-start">
                <strong className="text-green-500 mr-2">&#10003;</strong>
                <span>Improved Scalability: Easily scale as your business grows, ensuring seamless operations.</span>
              </li>
              <li className="mb-2 flex items-start">
                <strong className="text-green-500 mr-2">&#10003;</strong>
                <span>Enhanced Security: Prioritize data security with robust measures, keeping sensitive information safe.</span>
              </li>
              <li className="mb-2 flex items-start">
                <strong className="text-green-500 mr-2">&#10003;</strong>
                <span>User-Friendly Interface: Enjoy intuitive software design for a pleasant user experience.</span>
              </li>
              <li className="mb-2 flex items-start">
                <strong className="text-green-500 mr-2">&#10003;</strong>
                <span>Customizable Features: Tailor features to suit your specific requirements.</span>
              </li>
              <li className="mb-2 flex items-start">
                <strong className="text-green-500 mr-2">&#10003;</strong>
                <span>Ongoing Support: Comprehensive support and maintenance services beyond development.</span>
              </li>
            </ul>
          </div>
        </div>


        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Product details */}
          <div className="p-4 mt-10">
            <img src="./Images/About2.jpg" alt="About main-1img" className="rounded-lg shadow-md mx-auto md:mx-0" style={{ maxWidth: '100%' }} />
          </div>

          {/* Product image */}
          <div className="p-4 mt-10">
            <h2 className="text-3xl font-bold mb-4">Vila Campus Build Software With Strategy, Design, & User Experience That Drive Excellent Results</h2>
            <p className="mb-4">VILA Campus is a product development company. We are a team of experienced professionals who are skilled in modern technologies to provide end-to-end product development services. With a focus on design thinking and rapid turnaround time, we help you bring a cutting-edge product which caters to the demands of the current market and customers.</p>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <FaCircleCheck className="text-green-500 mr-2" />
                <span className='font-bold'>Responsive Design</span>
              </div>
              <div className="flex items-center">
                <FaCircleCheck className="text-green-500 mr-2" />
                <span className='font-bold'>Software Product Development</span>
              </div>
              <div className="flex items-center">
                <FaCircleCheck className="text-green-500 mr-2" />
                <span className='font-bold'>Migration of Existing Software</span>
              </div>
              <div className="flex items-center">
                <FaCircleCheck className="text-green-500 mr-2" />
                <span className='font-bold'>Support and Maintenance of Software</span>
              </div>
            </div>
          </div>

        </div>
        <hr className='my-10 border-t-2 border-gray-500' />
        <p className='mt-10 text-center'>The technology-driven services, high-end security, mobility, automation, customizable applications, scalable solutions, around twenty years of experience, and affordability is what makes us unique from others.</p>

        {/* cards */}
        <div className="bg-blue-100 mb-10">
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-between mt-10 p-5 ">
              <div className="w-full md:w-1/2 lg:mb-0 lg:pr-2">
                <div className="bg-white rounded-lg shadow-md p-4 h-full">
                  <img src='./Images/About-short-1.png' alt='About-img1' className="mx-auto" style={{ maxWidth: '80%' }} />
                  <h3 className="text-lg font-semibold mb-2 text-center">Business Excellence</h3>
                  <p className="text-center">We are one of the fastest-growing software companies. The technology-driven services, high-end security, mobility, customizable applications, scalable solutions, around twenty years of experience, and affordability are what make us unique from others.</p>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:pl-2">
                <div className="bg-white rounded-lg shadow-md p-4 h-full">
                  <img src='./Images/About-short-2.png' alt='About-img2' className="mx-auto" style={{ maxWidth: '80%' }} />
                  <h3 className="text-lg font-semibold mb-2 text-center">Engineering Excellence and Quality</h3>
                  <p className="text-center">We follow delivering working software that meets the quality aspects like design, architecture, coding, testing, release engineering, requirements, environment, and usability ensures software engineering excellence.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
