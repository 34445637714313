import React from 'react';

const OurServices = () => {
    return (
        <div>
            <div className='container mx-auto lg:mt-10'>
                <p className='text-center text-5xl mt-3 font-bold'>Our Main Services</p>
                <div className="flex flex-wrap justify-center items-center mt-10">
                    {/* Card 1 */}
                    <div className="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 rounded overflow-hidden shadow-md mx-4 text-center mb-6 hover:bg-blue-200">
                        <div className="flex justify-center">
                            <img src="./Images/ERP.png" alt="Card Image" className="w-full h-auto" style={{ maxWidth: '40%', height: 'auto' }} />
                        </div>
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">ERP Software Development</div>
                            <p className="text-gray-700 text-base">ERP software development involves analyzing organizational requirements, designing, developing, and integrating systems to streamline business processes and optimize resource management.</p>
                        </div>
                    </div>


                    {/* Card 2 */}
                    <div className="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 rounded overflow-hidden shadow-lg mx-4 hover:bg-blue-100 text-center mb-6">
                        <div className="flex justify-center">
                            <img src="./Images/Website OurService.png" alt="Card Image" className="w-full h-auto" style={{ maxWidth: '40%', height: 'auto' }} />
                        </div>
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">Website Developement</div>
                            <p className="text-gray-700 text-base">Website development involves creating and deploying web-based applications or pages using technologies like HTML, CSS, and JavaScript, to provide information or services tailored to client needs and user experience.</p>
                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className="max-w-sm w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 rounded overflow-hidden shadow-lg mx-4 hover:bg-blue-100 text-center mb-6">
                        <div className="flex justify-center">
                            <img src="./Images/Digital OurServices.png" alt="Card Image" className="w-full h-auto" style={{ maxWidth: '40%', height: 'auto' }} />
                        </div>
                        <div className="px-6 py-4">
                            <div className="font-bold text-xl mb-2">Digital Marketing</div>
                            <p className="text-gray-700 text-base">Digital marketing involves leveraging online channels such as social media, search engines, email, and content creation to reach and engage with target audiences, driving brand awareness, lead generation, and conversions for businesses.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
