import React from 'react'
import Navbar from './Components/Home/Navbar'
import Headpage from './Components/Home/Headpage'
import Footer from './Components/Home/Footer'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from './Components/Pages/About'
import Career from './Components/Pages/Career'
import Contact from './Components/Pages/Contact'
import Product from './Components/Pages/Product.js'
import Privacy from './Components/Pages/Privacy.js'
import Terms from './Components/Pages/Terms.js'
import AdminLogin from './Components/Pages/AdminLogin.jsx'
import SendSMS from './Components/Pages/SendSMS.jsx'


const App = () => {

  return (
    <div>
      <BrowserRouter >
      <Navbar />
      <Routes>
      <Route exact path="/" element={<Headpage />}/>
      <Route path='/About' element={<About />}/>
      <Route path='/Career' element={<Career />}/>
      <Route path='/Product' element={<Product />}/>
      <Route path='/Contact' element={<Contact />}/>
      <Route path='/Privacy' element={<Privacy />}/>
      <Route path='/Terms' element={<Terms />}/>
      <Route path='/AdminLogin' element={<AdminLogin />}/>
      <Route path='/sendsms' element={<SendSMS  />}/>
      </Routes>
      <Footer />
      </BrowserRouter> 
      
    </div>
  )
}

export default App
