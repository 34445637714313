import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import  { baseUrl } from '../../appconfig';
import axios from 'axios';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setUsername(value);
    setError("");
    validateForm(value, password);
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setError("");
    validateForm(username, value);
  };

  const validateForm = (username, password) => {
    setFormValid(username.trim() !== '' && password.trim() !== '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username && password ) {
    const response =await  axios.post(`${baseUrl}user/authenticate`,
        {userName:username,userPassword:password},
        {headers:{
          "Content-Type":'application/json'
        }})
    const res = response.data;
    if(res?.result?.token){
      localStorage.setItem("vcptoken",res?.result?.token);
        navigate('/sendsms');
    }else{
      setError('Invalid username or password');
    }
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="min-h-[500px]">
      <form onSubmit={handleSubmit}>
        <div className="space-y-10 flex justify-center my-10">
          <div className="border-gray-900/10  w-[400px] border-2 rounded-lg py-6 px-2">
            <Link to="/" className="flex justify-center my-4"><img src='./Images/VILA_Campus_Logo.png' alt='logo' className='w-20 h-20'/></Link>
            <h2 className="flex justify-center font-bold text-xl leading-7 text-gray-900">Login</h2>
            <div className="mt-10 flex flex-col gap-6">
            {error&&<h2 className="font-meduim text-sm  text-red-600">*{error}</h2>}
              <div className="w-full">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Username:
                </label>
                <div className="mt-2 w-full">
                  <input
                    type="text"
                    name="username"
                    id="username"
                    value={username}
                    onChange={handleUsernameChange}
                    className="block rounded-lg w-full flex-1 border-[1px] border-gray-400 focus-visible:border-gray-400 font-semibold bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    placeholder="username"
                    required
                  />
                </div>
              </div>
              <div className="w-full">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password:
                </label>
                <div className="mt-2 w-full">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    value={password}
                    onChange={handlePasswordChange}
                    className="block rounded-lg w-full flex-1 border-[1px] border-gray-400 focus-visible:border-gray-400 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                    placeholder="password"
                    required
                  />
                </div>
              </div>
              <div className="sm:col-span-4 flex justify-end">
                <button
                  type="submit"
                  className={`text-sm font-bold ${!formValid ? `bg-blue-200`:`bg-blue-600`} p-2 text-white rounded-lg w-28`}
                  disabled={!formValid}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdminLogin;
