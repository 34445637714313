import React from 'react';

const Career = () => {
  return (
    <div>
      <div className="min-h-80 flex bg-cover bg-center " style={{ backgroundImage: "url('./Images/Career-baner.avif')" }}>
        <div className=" inset-0 flex items-center justify-center">
          <div className="text-white  p-4 md:p-8 rounded-lg max-w-md text-center">
            <h1 className="text-6xl font-bold">Career</h1>
          </div>
        </div>
      </div>

      <div className="text-center text-black mt-10">
        <p className="text-4xl font-bold">Work @ VILA Campus</p>
        <p className="mt-3">We have a team of highly organized and hard-working individuals looking for responsible positions to gain practical experience.</p>
        <p className="mt-3 font-bold">Please send your profile to info@vilacampus.com</p>
        <p className="mt-5 font-bold text-4xl">Current Openings</p>
        <p className="mt-3">We have the following job openings for people who want to be rising stars.</p>
      </div>
      <div className="container mx-auto mt-10 mb-10 overflow-x-auto">
        <table className="border-collapse border border-gray-400">
          <thead className="bg-blue-300">
            <tr>
              <th className="border border-gray-400 px-4 py-2">Title</th>
              <th className="border border-gray-400 px-4 py-2">Description</th>
              <th className="border border-gray-400 px-4 py-2">Skills</th>
              <th className="border border-gray-400 px-4 py-2">Experience</th>
            </tr>
          </thead>
          <tbody>
            <tr className='hover:bg-gray-300'>
              <td className="border border-gray-400 px-4 py-2">Full Stack Developer</td>
              <td className="border border-gray-400 px-4 py-2">
                <p className="mt-3">Contribute to all phases of the development life-cycle.</p>
                <p className="mt-3">Key member of the product development team that helps shape the capabilities of the product.</p>
                <p className="mt-3">Good working experience with Restful Services and API Integration. Experience in responsive UI design.</p>
                <p className="mt-3">Ensuring high performance of applications and providing support.</p>
              </td>
              <td className="border border-gray-400 px-4 py-2">HTML 5, Bootstrap, CSS, JavaScript, React.Js, Material, Node JS, MySQL</td>
              <td className="border border-gray-400 px-4 py-2">2-5</td>
            </tr>
            <tr className='hover:bg-gray-300'>
              <td className="border border-gray-400 px-4 py-2">QA Tester</td>
              <td className="border border-gray-400 px-4 py-2">
                <p className="mt-3">Quality test for Software application.</p>
                <p className="mt-3">Write and Execute test cases.</p>
                <p className="mt-3">Analyzing test results and reporting to the development team.</p>
                <p className="mt-3">Working with the development team to correct if any bugs and errors.</p>
                <p className="mt-3">knowledge on JIRA.</p>
              </td>
              <td className="border border-gray-400 px-4 py-2">Manual Testing, JIRA</td>
              <td className="border border-gray-400 px-4 py-2">2-5</td>
            </tr>
            <tr className='hover:bg-gray-300'>
              <td className="border border-gray-400 px-4 py-2">Flutter Developer</td>
              <td className="border border-gray-400 px-4 py-2">
                <p className="mt-3">Develop mobile applications using Flutter framework.</p>
                <p className="mt-3">Collaborate with cross-functional teams to define, design, and ship new features.</p>
                <p className="mt-3">Write clean, maintainable, and efficient code.</p>
                <p className="mt-3">Ensure the performance, quality, and responsiveness of applications</p>
                <p className="mt-3">Identify and correct bottlenecks and fix bugs.</p>
              </td>
              <td className="border border-gray-400 px-4 py-2">Flutter Framework,Dart Programming Language,UI/UX Design,API Integration,State Management,Debugging and Testing,Problem-Solving Skills</td>
              <td className="border border-gray-400 px-4 py-2">2-5</td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Career;
