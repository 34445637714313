import React from 'react'

const Terms = () => {
    return (
        <div>
            <div className='container mx-auto'>
                <h1 className='text-5xl font-bold'>GENERAL TERMS OF USE  </h1>
                <p className='mt-2 font-semibold'>This document/agreement/understanding is a computer-generated electronic record published in terms of the Information Technology and does not require any physical or digital signatures.  </p>
                <p className='mt-5'>Whereas the Merchant intends to avail online Services, the Merchant understands that online Services will be provided by V Link Jobs. The Merchant understands that the provision of Online Services will be governed by general terms of use. </p>
                <p className='mt-5'>These terms including annexures and links herein, apply to use of https://vilacampus.com/any other linked pages, API keys, features, content or application services (including but without limitation to any mobile application, mobile site services) (“Website”) owned and operated by VILA Campus LLP, “Applicable Laws” shall mean (i) any law, statute, rule, regulation, order, circular, decree, directive, judgment, decision or other similar mandate of any applicable central, national, state or local governmental/regulatory Authority having competent jurisdiction and force of law over, or applicable to You, us or the subject matter in question, as may be amended from time to time, and (ii) shall without limitation include any notification, circular, directive or other similar instruction issued by the ‘Financial Sector Regulators' including but not limited to the Reserve Bank of India (RBI) and/or rules, regulations, roles, responsibilities and processes as defined by NPCI.</p>
                <p className='mt-5'>Before You may use the Website, You must read all of these General Terms of Use and the Specific Terms of Use (defined below) (hereinafter together referred to as the “Terms”) and the Privacy Policy provided on the Website. By using VILA Campusand its affiliates' products, software, services, and the Website or by availing any products offered, You understand and agree that VILA Campuswill treat Your use of the Services as acceptance of these Terms from such point of usage. You agree that VILA Campusreserves the right to update the Services from time to time. </p>
                <p className='mt-5'>You acknowledge and agree that all references to “V Link Jobs” shall mean for the Specific Terms of Use. You further agree that any claims relating to or arising from the Specific Terms of Use shall be brought solely against such affiliates providing Services. You may not use the Services if You do not accept the Terms. If You do not agree to be bound by these Terms and the Privacy Policy, You may not use the Website in any way. It is strongly recommended for You to return to this page periodically to review the most current version of the Terms in force. VILA Campusreserves the right at any time, at its sole discretion, to change or otherwise modify the Terms without prior notice, and Your continued access or use of the Website signifies Your assent/ratification of the updated or modified Terms.</p>
                <h1 className='text-3xl font-bold mt-3'>1. PROPRIETARY RIGHTS  </h1>
                <p className='mt-5'>VILA Campusgrants You a personal, non-exclusive, non-transferable, limited privilege to avail the Services and make personal use of the Website and/or the Services. This license does not confer any right to download, copy, create a derivative work, modify, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sub-license, grant a security interest in or otherwise transfer any right in the Services. You do not have the right to use any of V Link Jobs's trade names, trademarks, service marks, logos, domain names, and other distinctive brand features. You do not have the right to remove, obscure, or alter any proprietary rights notices (including trademark and copyright notices), which may be affixed to or contained within the Services. You will not copy or transmit any of the Services. </p>
                <h1 className='text-3xl font-bold mt-3'>2. USAGE OF THE WEBSITE AND USE OF SERVICES BY THE USER   </h1>
                <p className='mt-5'>2.1. You shall register to become a user of the Website only if You are intended to use and can enter into binding contracts as per Applicable Laws. You are responsible for maintaining the secrecy of Your passwords, login and account information. You will be responsible for all use of the Website and/ or Services by You and anyone using Your password and login information (with or without our permission). You are responsible for maintaining the confidentiality of any login information and secure access credentials associated with Your VILA Campusaccount. Accordingly, You are responsible for all activities that occur under Your account/in using Your secure credentials and VILA Campusshall not be liable for any such change or action performed by using Your secure credentials on the Website. </p>
                <p className='mt-5'>2.2. You also agree to provide true, accurate, current and complete information about Yourself as and when prompted by the Website. If You provide any information that is untrue, inaccurate, not updated or incomplete (or becomes untrue, inaccurate or incomplete), or we have reasonable grounds to suspect that such information is untrue, inaccurate, not updated or incomplete, VILA Campusshall have the right to suspend or terminate Your account and/or refuse any and all current or future use of the Website (or any portion thereof) or Services in connection thereto.</p>
                <p className='mt-5'>2.3. By making use of the Website, and furnishing Your contact details, You hereby agree that You are interested in knowing more or availing and/or purchasing various products, services that VILA Campusor any other third party may offer to /provide to /share with /send to You from time to time through any means including but not limited to telephone, SMS (short messaging service), electronic mail (e- mail), WhatsApp or any other messaging service/mobile application or any other physical, electronic or digital means/mode. You hereby agree that we may contact You either electronically or through phone, to understand Your interest in the selected products and Services and to fulfil Your demand or complete Your application. Further, You also expressly agree and authorize V Link Jobs, its partners, service providers, vendors and other third parties to contact You for the purpose of (i) offering or inviting Your interest in availing other products or services offered by third parties, or (ii) for sending other marketing campaigns, offers, or any other information either on the Website or through other means including but not limited to telephone, SMS (short messaging service), electronic mail (e-mail), WhatsApp or any other messaging service/mobile application or any other physical, electronic or digital means/mode. </p>
                <p className='mt-5'>2.4. You specifically understand and agree that by using the Website You authorize V Link Jobs, its affiliates, partners and third parties to contact You for any follow up calls in relation to the Services provided through the Website and/ or for offering or inviting Your interest in availing any other product or service offered by us. You agree and consent to receive communications relating to all of the above on Your phone/mobile number (if any provided) by You on the Website and expressly waive any registration or preference made under DND/NCPR list under the applicable Telecom Regulatory Authority of India (TRAI) regulations.  </p>
                <p className='mt-5'>2.5. You agree and authorize VILA Campusto share Your information and make Your details available to its partner banks/financial institutions, its group companies, affiliates, vendors, service providers/Facility Providers and other third parties, in so far as required for providing various products and services and/or to provide You with various value-added services, in association with the Services selected by You or generally otherwise. You agree to receive communications through emails, telephone and/or SMS, from VILA Campusor third parties. If You request not to receive such communication/marketing material any further, it shall be applicable prospectively only and shall not apply in respect to Your data already shared in accordance with this clause upon Your prior consent. </p>
                <p className='mt-5'>2.6. You agree and acknowledge that for undertaking any payment and/or financial transaction through the Website, VILA Campusmay undertake due diligence measures and seek information required for Know-Your- Customer (“KYC”) purpose which as a customer/merchant You are obliged to give in accordance with Applicable Laws. You agree and acknowledge that we can undertake enhanced due diligence measures (including any documentation), to satisfy itself relating to its due diligence requirements in line with the requirements and obligations under Applicable Laws. You are solely responsible for understanding and complying with all applicable laws of Your specific jurisdiction that may be applicable to You in connection with Your business and use of Services. </p>
                <p className='mt-5'>2.7. The usage of the Website may also require You to provide consent for providing Your Personal Information (“PI”) (including but not limited to any personal data or sensitive personal data as defined under Applicable Laws) or to authorize VILA Campusto derive Your data/information from any source or public registry, as may be necessary to complete Your profile or Your application on the Website, conduct due diligence on You, undertake KYC checks by itself or any other third party and/or to provide You Services through this Website. VILA Campusshall adhere to best industry practices including information security, data protection and privacy law while processing such applications. However, VILA Campusshall not be liable to You against any liability or claims which may arise out of such transactions as any such PI is being collected, used, processed and shared with Your explicit consent. </p>
                <p className='mt-5'>2.8. You agree not to use the Website and/ or Services for any purpose that is unlawful, illegal or forbidden by these Terms, or any local laws that might apply to You. Since the Website is in operation in India, while using the Website and/ or the Services, You shall agree to comply with laws that apply in India. We may, at our sole discretion, at any time and without advance notice or liability, suspend, terminate or restrict Your access to all or any component of the Website and/ or Services. </p>
                <p className='mt-5'>2.9. You are prohibited from posting or transmitting to or through this Website: (i) any unlawful, threatening, libellous, defamatory, obscene, pornographic or other material or content that would violate rights of publicity and/or privacy or that would violate any law or that harms minors in any way; (ii) any commercial material or content (including, but not limited to, solicitation of funds, advertising, or marketing of any good or services); (iii) any material or content that infringes, misappropriates or violates any copyright, trademark, patent right or other proprietary right of any third party; (iv) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; (v) threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting to any other nation; (vi) impersonates another person; or (vii) is illegal in any other way. You shall be solely liable for any damages resulting from any violation of the foregoing restrictions, or any other harm resulting from Your posting of content to this Website. </p>
                <p className='mt-5'>2.14. You represent and warrant that:  </p>
                <p className='mt-5'>(a) You are duly incorporated or established under the laws of Your jurisdiction and have all requisite power and authority to own and operate Your business. </p>
                <p className='mt-5'>(b) You have the full legal capacity and power to enter into, exercise Your rights under, and perform Your obligations under these Terms.  </p>
                <p className='mt-5'>(c) The execution, delivery and performance of these Terms has been authorized by all necessary corporate and organizational actions including but not limited to board resolution and/or power of attorney and/or letter of authority to bind Your business and Your company/firm/organization.  </p>
                <p className='mt-5'>(d) You have duly accepted these Terms, which form a legal, valid and binding obligation, enforceable in accordance with its clauses.  </p>
                <p className='mt-5'>2.15. You further covenant and agree that You shall ensure that:   </p>
                <p className='mt-5'>(a) Your use of the Services and sale of Your products/services are solely for Your own bona fide business activities which are in compliance with the Applicable Laws and also the instructions issued from time to time by the VILA Campusand its Facility Providers. </p>
                <p className='mt-5'>(b) Your use of the Services corresponds to those activities under the categories You have expressly registered for at the time of entering into these Terms and as set out in Your onboarding form on the dashboard or as otherwise approved by V Link Jobs. </p>
                <p className='mt-5'>(c) Your use of Services does not facilitate any activity which is unlawful, illegal, unauthorised, is carried on with the intent to defraud, or is likely to result in Your unjust enrichment and/or unlawful gain. </p>
                <h1 className='text-3xl font-bold mt-3'>3. PAYMENT   </h1>
                <p className='mt-5'>3.1. Applicable fees for the provision of Services shall be levied by VILA Campusfrom time to time. You agree that the fees shall be charged according to the manner, rates and frequency determined. VILA Campusreserves the right to update the amount of the fees charged at its sole discretion. </p>
                <p className='mt-5'>3.2. Fees are exclusive of applicable taxes and will charge such applicable taxes on the fees from time to time. You agree that any statutory variations in applicable taxes during the subsistence of these Terms shall be borne by You</p>
                <h1 className='text-3xl font-bold mt-3'>4. THIRD PARTY LINKS/OFFERS    </h1>
                <p className='mt-5'>This Website may provide links to other websites or resources. Since VILA Campushas no control over such third-party websites and resources, You acknowledge and agree that VILA Campusis not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. You further acknowledge and agree that VILA Campusshall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource. Your interaction with any third party accessed through the Website is at Your own risk, and VILA Campuswill have no liability with respect to the acts, omissions, errors, representations, warranties, breaches or negligence of any such third parties or for any personal injuries, death, property damage, or other damages or expenses resulting from Your interactions with the third parties. </p>
                <h1 className='text-3xl font-bold mt-3'>5. DISCLAIMER OF WARRANTY    </h1>
                <p className='mt-5'>VILA Campuswarrants that the Service(s) shall be provided materially in accordance with the services documentation available on the Website. VILA Campuswarrants that the Service(s) will perform in all material respects in accordance with these Terms. To the maximum extent permitted by Applicable Laws and except as stated in these Terms, the Services are provided on an “as is” basis. You acknowledge that VILA Campusdoes not warrant that the Service(s)s will be uninterrupted or error free or fit for Your specific business purposes. </p>
                <h1 className='text-3xl font-bold mt-3'>6. ADVERTISING   </h1>
                <p className='mt-5'>Some of the Services may be supported by advertising revenue and may display advertisements and promotional material. These advertisements may be targeted to the content of information stored on the Services, queries made through the Services or other information. The manner, mode and extent of advertising by VILA Campusare subject to change without any specific notice to You. In consideration for granting You access to and use of the Services, You agree that VILA Campusmay place such advertisements on the Services through Website, print media, electronic media, and social media advertising platforms etc. </p>
                <h1 className='text-3xl font-bold mt-3'>7. SUSPENSION AND TERMINATION    </h1>
                <p className='mt-5'>Notwithstanding anything to the contrary, VILA Campusshall have the right to immediately suspend Services and settlement of any monies or payments to You, without any liability to You, in the event of the following: </p>
                <p className='mt-5'>(a) You breach any clause of these Terms.<br/>(b) You facilitate any transaction which is unlawful or in contravention with ‘Prohibited Products and Services' listed below.  </p>
                <p className='mt-5'>(c) VILA Campusreceives instructions from Facility Providers or governmental authorities or law enforcement agencies to either suspend the Services, or part thereof, or directs to suspend the Services or part thereof regardless of whether there is pending investigation/enquiry into any alleged illegal/unlawful activities.  </p>
                <p className='mt-5'>(d) You use the Services for any transactions which have a high-risk score as per internal fraud assessment tools and other policies.  </p>
                <p className='mt-5'>Termination does not immediately relieve You of obligations incurred by You under these Terms. Upon termination, You agree to stop using the Services. Your continued or renewed use of the Services after termination serves to renew Your consent to the Terms. In addition, upon termination You understand and agree that We will not be liable to You for compensation, reimbursement, or damages related to Your use of the Services, or any termination or suspension of the Services or deletion of Your information or account data; and You will still liable to us for any fees or fines, or other financial obligation incurred by You or through Your use of the Services prior to termination.  </p>
                <h1 className='text-3xl font-bold mt-3'>8. PROHIBITED PRODUCTS AND SERVICES    </h1>
                <p className='mt-5'>1. Adult goods and services which includes pornography and other sexually suggestive materials (including literature, imagery and other media); escort or prostitution services; Website access and/or Website memberships of pornography or illegal sites. Child pornography which includes pornographic materials involving minors.</p>
                <p className='mt-5'>2. Alcohol which includes alcohol or alcoholic beverages such as beer, liquor, wine, or champagne.</p>
                <p className='mt-5'>3. Body parts which includes organs or other body parts.</p>
                <p className='mt-5'>4. Copyrighted software which includes unauthorized copies of software, video games and other licensed or protected materials.</p>
                <p className='mt-5'>5. Drugs and drug paraphernalia which includes illegal drugs and drug accessories, including herbal drugs like salvia and magic mushrooms.</p>
                <p className='mt-5'>6. Government IDs or documents which includes fake IDs, passports, diplomas, and noble titles.</p>
                <p className='mt-5'>7. Hacking and cracking materials which includes manuals, how-to guides, information, or equipment enabling illegal access to software, servers, website, or other protected property.</p>
                <p className='mt-5'>8. Illegal goods which includes materials, products, or information promoting illegal goods or enabling illegal acts; 18. Miracle cures which includes unsubstantiated cures, remedies or other items marketed as quick health fixes.</p>
                <p className='mt-5'>9. Offensive goods which includes literature, products or other materials that: (a) Defame or slander any person or groups of people based on race, ethnicity, national origin, religion, sex, or other factors; (b) Encourage or incite violent acts; or (c) Promote intolerance or hatred. Offensive goods, crime which includes crime scene photos or items, such as personal belongings, associated with criminals</p>
                <p className='mt-5'>10. Tobacco and cigarettes which includes cigarettes, cigars, chewing tobacco, and related products.</p>
                <p className='mt-5'>11. Wholesale currency which includes discounted currencies or currency exchanges.</p>
                <p className='mt-5'>12. Live animals or hides/skins/teeth, nails and other parts etc. of animals.</p>
                <h1 className='text-3xl font-bold mt-3'>9. GENERAL    </h1>
                <p className='mt-5'>You may opt for certain value added services which will be subject to an additional charge to be agreed upon by the parties. Such charges are to be paid on a monthly / quarterly / annual basis or other frequency as may be agreed between the parties. You hereby consent that payments towards such value added services shall be deducted from the settlement amount payable from Escrow Account under these Terms. You acknowledge that VILA Campusprovides as a value added service certain customisable templates for terms of use and conditions. </p>
                <p className='mt-5'>You agree that the fees shall be charged according to the manner, rates and frequency determined. VILA Campusreserves the right to update the amount of the Fees including for Services for which no charge has been levied previously in accordance with this clause.  </p>
                <h1 className='text-3xl font-bold mt-3'>Refunds   </h1>
                <p className='mt-5'>Once the service obtained by the user there is no refund provided to the user. Once paid for any of the service to  VILA Campususer has to use or ignore the service.</p>
                <h1 className='text-3xl font-bold mt-3'>Payment   </h1>
                <p className='mt-5 mb-5'>Charges associated with the provision of Services to the Merchant shall be in accordance with the Pricing Terms agreed to by the VILA Campusat the time of subscribing to the Services on the Website. The Merchant agrees that such Fees shall be charged according to the manner, rates and frequency specified in the said pricing terms. </p>
            
            
            
            
            
            
            
            
            
            
            
            </div>
        </div>
    )
}

export default Terms
